import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/stats',
      name: 'stats',
      component: () => import('@/views/stats.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/auto-replies',
      name: 'auto-replies',
      component: () => import('@/views/chat/auto-replies.vue')
    },
    {
      path: '/tags',
      name: 'tags',
      component: () => import('@/views/chat/tags.vue')
    },
    {
      path: '/ai',
      name: 'ai',
      component: () => import('@/views/ai.vue')
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import('@/views/chat/templates.vue')
    },
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/chat/inbox.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: () => import('@/views/chat/inbox.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/autologin/:id',
      name: 'autologin',
      component: () => import('@/views/pages/autologin.vue')
    },
    {
      path: '/autologin2',
      name: 'autologin2',
      component: () => import('@/views/pages/autologin.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/views/pages/activate.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
