import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

import VueSocketIO from 'vue-3-socket.io'
import SocketIO from "socket.io-client"

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "http://127.0.0.1:3001"
if(window.location.href.includes(".com")){
  window.api = "https://serverocto.smart-whats.com"
}
if(localStorage.getItem('testSite')){
  window.api = "https://serverocto2.smart-whats.com/"
}

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

import AudioRecorder from 'vue-audio-recorder'

Vue.use(AudioRecorder)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if(window.location.href.includes("autologin")){
  localStorage.setItem("user", JSON.stringify({jwt: window.location.href?.split("autologin/")[1]}))
  localStorage.setItem("user", JSON.stringify({jwt: window.location.href?.split("autologin/")[1]}))
  if(window.location.href.includes("?")){
  localStorage.setItem("user", JSON.stringify({jwt: window.location.href.split("?=")[1]}))
  }
  window.location = '/'
}

var user = {}

if(localStorage.getItem("user")){
  user = JSON.parse(localStorage.getItem('user'))
}

global.origin_id = window.location.hash.replace("#", "");

if(user.jwt && window.location.pathname == '/chat' || user.jwt && window.location.pathname == '/inbox'){
  Vue.use(new VueSocketIO({
    debug: false,
    connection: SocketIO(window.api, {
        auth: {
          isMobile: screen.width <= 480,
          socket_id: user.socket_id,
          name: user.name,
          device_id: window.location.hash.replace("#", "")
        }
    })
}))
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
